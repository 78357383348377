import { render, staticRenderFns } from "./general.vue?vue&type=template&id=1b6f7abe&scoped=true"
import script from "./general.vue?vue&type=script&lang=js"
export * from "./general.vue?vue&type=script&lang=js"
import style0 from "./general.vue?vue&type=style&index=0&id=1b6f7abe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b6f7abe",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QSelect});
